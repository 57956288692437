import { botThemeApi } from "src/__Api__/botThemeApi"; 
import {
  FETCH_ALL_BOT_THEMES_START,
  FETCH_ALL_BOT_THEMES_END,
  FETCH_ALL_BOT_THEMES_RECIEVES,
  FETCH_SINGLE_BOT_THEME_END,
  FETCH_SINGLE_BOT_THEME_RECIEVES,
  FETCH_SINGLE_BOT_THEME_START,
  FETCH_POLICIES_FOR_BOT_THEMES_END,
  FETCH_POLICIES_FOR_BOT_THEMES_RECIEVES,
  FETCH_POLICIES_FOR_BOT_THEMES_START
} from "./action-types";
import { isString } from "lodash";
import { CreateBotTheme } from "src/types/botTheme"; 
import { toast } from "react-hot-toast";

export const fetchAllBotThemes = (handleLoadingStates: any) => async (dispatch) => {
  handleLoadingStates('fetchAllBotThemes', true)
  try {
    dispatch({ type: FETCH_ALL_BOT_THEMES_START });
    const result = await botThemeApi.fetchAllBotThemes();
    handleLoadingStates('fetchAllBotThemes', false)
    if (isString(result)) {
      dispatch({
        type: FETCH_ALL_BOT_THEMES_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_ALL_BOT_THEMES_RECIEVES,
        data: result,
        error: "",
      });
    }

  } catch (e) {
    handleLoadingStates('fetchAllBotThemes', false)
    console.log(e);
  }
}

export const getCreditFeaturePoliciesForBotThemes =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("fetchAllEmailPolicies", true);
      dispatch({ type: FETCH_POLICIES_FOR_BOT_THEMES_START });

      const result = await botThemeApi.getCreditFeaturePoliciesForBotThemes();
      handleLoadingStates("fetchAllEmailPolicies", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_POLICIES_FOR_BOT_THEMES_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_POLICIES_FOR_BOT_THEMES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_POLICIES_FOR_BOT_THEMES_END,
        data: [],
        error,
      });
    }
  };

export const fetchBotTheme = (id: string, handleLoadingStates: any) => async (dispatch) => {
  handleLoadingStates('fetchBotTheme', true)
  try {
    dispatch({ type: FETCH_SINGLE_BOT_THEME_START });
    const result = await botThemeApi.fetchBotTheme(id);
    handleLoadingStates('fetchBotTheme', false)
    if (isString(result)) {
      dispatch({
        type: FETCH_SINGLE_BOT_THEME_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_SINGLE_BOT_THEME_RECIEVES,
        data: result,
        error: "",
      });
    }

  } catch (e) {
    handleLoadingStates('fetchBotTheme', false)
    console.log(e);
  }
}

export const createBotTheme = (handleLoadingStates: any, body: CreateBotTheme, setIsCreateBotThemeModal: any) => async (dispatch) => {
  handleLoadingStates('createBotTheme', true)
  try {
    await botThemeApi.createBotTheme(body);
    handleLoadingStates('createBotTheme', false)

    setIsCreateBotThemeModal(false)
    dispatch(fetchAllBotThemes(handleLoadingStates))

  } catch (e) {
    handleLoadingStates('createBotTheme', false)
    console.log(e);
  }
}

export const deleteBotTheme = (id: string, handleLoadingStates: any, setIsDeleteBotThemeModal:any) => async (dispatch) => {
  try {
    handleLoadingStates('deleteBotTheme', true)
    const result = await botThemeApi.deleteBotTheme(id);
    handleLoadingStates('deleteBotTheme', false)
    if(isString(result)){
      setIsDeleteBotThemeModal(false)
      dispatch(fetchAllBotThemes(handleLoadingStates))
    }

  } catch (e) {
    console.log(e);
  }
}

export const updateBotTheme = (id: string, body: CreateBotTheme, handleLoadingStates: any, setIsUpdateBotThemeModal:any) => async (dispatch) => {
  try {
    handleLoadingStates('updateBotTheme', true)
    const result = await botThemeApi.updateBotTheme(id, body);
    handleLoadingStates('updateBotTheme', false)
    if(isString(result)){
      toast.success(result, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setIsUpdateBotThemeModal(false)
      dispatch(fetchAllBotThemes(handleLoadingStates))
    }

  } catch (e) {
    console.log(e);
  }
}

