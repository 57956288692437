
 export interface CreateEmailBotPolicy {
    title:  string;
    type: EmailTypes;
    featurePolicyId: string;
  }

  export interface UpdateEmailBotPolicy {
    title?:  string;
    type?: EmailTypes;
    featurePolicyId?: string;
  }

  export enum EmailTypes {
    "GMAIL" = "GMAIL",
    "HOTMAIL" = "HOTMAIL"
  }