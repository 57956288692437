import { useState, type FC } from "react";
import { Box, Container, TextField, Autocomplete } from "@mui/material";
import DialogLayout from "../DialogLayout";
import { EmailTypes } from "src/types/emailBotPolicy";

const UpdateEmailBotPolicyModal: FC<any> = (props) => {
  const {
    isUpdateEmailBotPolicyModal,
    handleCloseUpdateModal,
    updateEmailBotPolicyInput,
    handleUpdateEmailBotPolicy,
    loadingStates,
    handleUpdateEmailBotPolicyInput,
    getDisplayValue,
    emailTypes,
    creditFeaturePolicies,  } = props;

  const [submitClicked, setSubmitClicked] = useState(false);

  const creditPolicyOptions =
    creditFeaturePolicies?.map((policy: any) => ({
      label: policy.title,
      id: policy.id,
    })) || [];

  return (
    <DialogLayout
      isOpen={isUpdateEmailBotPolicyModal}
      onClose={handleCloseUpdateModal}
      title="Update Email Bot Policy"
      action="Submit"
      handleAction={() => {
        setSubmitClicked(true);
        if (
          updateEmailBotPolicyInput.title &&
          updateEmailBotPolicyInput.featurePolicyId
        ) {
          handleUpdateEmailBotPolicy();
        }
      }}
      isLoading={loadingStates.updateEmailBotPolicy}
    >
      <>
        <Container
          sx={{
            display: "flex",
            gap: 2,
            mt: 3,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <TextField
              label="Email Bot Policy Title"
              value={updateEmailBotPolicyInput?.title}
              onChange={(e) =>
                handleUpdateEmailBotPolicyInput("title", e.target.value)
              }
              sx={{
                width: "100%",
                mb: 3,
              }}
              error={submitClicked && !updateEmailBotPolicyInput.title}
              helperText={
                submitClicked && !updateEmailBotPolicyInput.title
                  ? "Email Bot Policy title should not be empty."
                  : ""
              }
            />
            <Autocomplete
              options={creditPolicyOptions}
              getOptionLabel={(option: any) => option.label}
              value={
                creditPolicyOptions.find(
                  (option) =>
                    option.id === updateEmailBotPolicyInput.featurePolicyId
                ) || null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  handleUpdateEmailBotPolicyInput(
                    "featurePolicyId",
                    newValue.id
                  );
                }
              }}
              clearIcon={null}
              noOptionsText="No Credit Feature Policies available"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Credit Feature Policy"
                  sx={{
                    width: "100%",
                    mb: 3,
                  }}
                  error={
                    submitClicked && !updateEmailBotPolicyInput.featurePolicyId
                  }
                  helperText={
                    submitClicked && !updateEmailBotPolicyInput.featurePolicyId
                      ? "You must select credit feature policy."
                      : ""
                  }
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={getDisplayValue(updateEmailBotPolicyInput.type)}
              options={emailTypes}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              onChange={(e, value) => {
                handleUpdateEmailBotPolicyInput("type", EmailTypes[value]);
              }}
              filterOptions={(options, { inputValue }) => options}
              renderInput={(params) => (
                <TextField {...params} label="Feature Type" />
              )}
            />
          </Box>
        </Container>
      </>
    </DialogLayout>
  );
};

export default UpdateEmailBotPolicyModal;
