import { Helmet } from "react-helmet-async";
import {
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "src/icons/ChevronRight";
import useEmailBotPolicy from "src/hooks/useEmailBotPolicy";
import EmailBotPolicyTable from "src/components/dashboard/emailBotPolicy/EmailBotPolicyTable";
import CreateEmailBotPolicyModal from "src/components/dashboard/emailBotPolicy/CreateEmailBotPolicyModal";
import UpdateEmailBotPolicyModal from "src/components/dashboard/emailBotPolicy/UpdateEmailBotPolicyModal";

const EmailBotPolicyPage = () => {
  const {
    emailBotPolicies,
    setIsCreateEmailBotPolicyModal,
    isCreateEmailBotPolicyModal,
    creditFeaturePolicies,
    handleCloseCreateModal,
    emailBotPolicyInput,
    handleCreateEmailBotPolicy,
    loadingStates,
    handleEmailBotPolicyInput,
    getDisplayValue,
    handleDeleteEmailBotPolicy,
    // emailTypes,
    isUpdateEmailBotPolicyModal,
    setIsUpdateEmailBotPolicyModal,
    updateEmailBotPolicyInput,
    handleUpdateEmailBotPolicy,
    handleUpdateEmailBotPolicyInput,
    handleUpdateModal,
    handleActivateEmailPolicy,
    type,
    setType,
    emailtypes,
  } = useEmailBotPolicy();

  console.log("emailtypes: ", emailtypes);
  return (
    <>
      <Helmet>
        <title>APEXE3 Revolutions</title>
      </Helmet>
      <Container>
        <Grid position="relative" height="100vh" sx={{ py: 4, mt: 8 }}>
          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography color="textPrimary" variant="h5">
                Email Bot Policy Management
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Overview
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Email Bot Policy
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                gap: 2,
                mt: 3,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                sx={{ fontSize: "14px", borderRadius: "10px" }}
                onClick={() => setIsCreateEmailBotPolicyModal(true)}
              >
                Create Email Bot Policy
              </Button>
            </Box>
          </Grid>
          <Box
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "right",
              gap: 2,
            }}
          >
            <FormControl sx={{ width: "15%" }}>
              <InputLabel id="demo-simple-select-label" sx={{ pl: 1 }}>
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Feature"
                onChange={(e) => {
                  console.log("e.target.value: ", e.target.value);
                  setType(e.target.value);
                }}
                sx={{ borderRadius: "30px !important" }}
                MenuProps={{
                  PaperProps: {
                    sx: (theme) => ({
                      backgroundColor: theme.palette.background.paper,
                    }),
                  },
                }}
              >
                <MenuItem value={null}>All</MenuItem>
                {emailtypes?.length &&
                  emailtypes?.map((emailtype) => (
                    <MenuItem value={emailtype}>{emailtype}</MenuItem>
                  ))}
                {/* <MenuItem value={"GMAIL"}>GMAIL</MenuItem>
                <MenuItem value={"HOTMAIL"}>HOTMAIL</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
          <Grid item>
            <EmailBotPolicyTable
              emailBotPolicies={emailBotPolicies}
              handleDeleteEmailBotPolicy={handleDeleteEmailBotPolicy}
              loadingStates={loadingStates}
              handleUpdateModal={handleUpdateModal}
              handleActivateEmailPolicy={handleActivateEmailPolicy}
            />
          </Grid>
        </Grid>
        {isCreateEmailBotPolicyModal && (
          <CreateEmailBotPolicyModal
            creditFeaturePolicies={creditFeaturePolicies}
            setIsCreateEmailBotPolicyModal={setIsCreateEmailBotPolicyModal}
            isCreateEmailBotPolicyModal={isCreateEmailBotPolicyModal}
            handleCloseCreateModal={handleCloseCreateModal}
            emailBotPolicyInput={emailBotPolicyInput}
            handleCreateEmailBotPolicy={handleCreateEmailBotPolicy}
            loadingStates={loadingStates}
            handleEmailBotPolicyInput={handleEmailBotPolicyInput}
            getDisplayValue={getDisplayValue}
            emailTypes={emailtypes}
          />
        )}
        {isUpdateEmailBotPolicyModal && (
          <UpdateEmailBotPolicyModal
            creditFeaturePolicies={creditFeaturePolicies}
            isUpdateEmailBotPolicyModal={isUpdateEmailBotPolicyModal}
            handleCloseUpdateModal={() => setIsUpdateEmailBotPolicyModal(false)}
            updateEmailBotPolicyInput={updateEmailBotPolicyInput}
            handleUpdateEmailBotPolicy={handleUpdateEmailBotPolicy}
            loadingStates={loadingStates}
            handleUpdateEmailBotPolicyInput={handleUpdateEmailBotPolicyInput}
            getDisplayValue={getDisplayValue}
            emailTypes={emailtypes}
          />
        )}
      </Container>
    </>
  );
};

export default EmailBotPolicyPage;
