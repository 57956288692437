import { useRef, useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Avatar, Box, Button, ButtonBase, Popover } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const {
    user,
    // logout,
    logoutSSO,
  } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logoutSSO();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={user?.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef?.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 210, border: "none", borderRadius: "10px" },
        }}
      >
        {/* <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          > */}
        {/* {sso ? JSON.parse(userName) : user?.name} */}
        {/* </Typography> */}
        {/* <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            Professional Trader
          </Typography> */}
        {/* </Box> */}
        {/* <Divider /> */}
        {/* <Box sx={{ mt: 2 }}> */}
        {/* <MenuItem
            component={RouterLink}
            to="/dashboard/social/profile"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Profile
                </Typography>
              )}
            />
          </MenuItem> */}
        {/* <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              )}
            />
          </MenuItem> */}
        {/* </Box> */}
        <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="contained"
            sx={{ borderRadius: "10px" }}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
