import axios from "axios";
import { getAccessToken } from "src/utils/token";
import toast from "react-hot-toast";
import { emailBotEngineConfig } from "src/config";
import { CreateEmailBotPolicy, EmailTypes, UpdateEmailBotPolicy } from "src/types/emailBotPolicy";

class EmailBotPolicy {
  async getAllEmailBotPolicies() {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${baseUrl}/email-bot-policies`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async getAllEmailTypes() {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${baseUrl}/email-bot-policies/email-types`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async getEmailBotPoliciesByType(type: EmailTypes) {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(`${baseUrl}/email-bot-policies/by-type`,{type}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async getEmailBotPolicy(id: string) {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(
        `${baseUrl}/email-bot-policies/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async createEmailBotPolicy(body: CreateEmailBotPolicy) {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(
        `${baseUrl}/email-bot-policies`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        toast.success("Email Bot Policy Created Successfully", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async updateEmailBotPolicy(body: UpdateEmailBotPolicy, id: string) {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.patch(
        `${baseUrl}/email-bot-policies/${id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        toast.success("Email Bot Policy Updated Successfully", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async deleteEmailBotPolicy(id: string) {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.delete(
        `${baseUrl}/email-bot-policies/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        toast.success("Email Bot Policy Deleted Successfully", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async activateEmailBotPolicy(id: string) {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.post(
        `${baseUrl}/email-bot-policies/activate/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }

  async getCreditFeaturePoliciesForEmailBotPolicies() {
    try {
      const { baseUrl } = emailBotEngineConfig();
      const token = getAccessToken("accessToken");
      const response = await axios.get(`${baseUrl}/credit-feature-policies`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });

      if (response != null && response.data) {
        return response.data;
      } else {
        toast.success("No data recieved", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        throw new Error("No data recieved");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return e?.response?.data?.message || "Something went wrong";
    }
  }
}

export const emailBotPolicy = new EmailBotPolicy();
