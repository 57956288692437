import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "src/store";
import {
  activateEmailBotPolicy,
  createEmailBotPolicy,
  deleteEmailBotPolicy,
  getAllEmailBotPolicies,
  getAllEmailTypes,
  getCreditFeaturePoliciesForEmailBotPolicies,
  getEmailBotPoliciesByType,
  updateEmailBotPolicy,
} from "src/store/emailBotPolicy/action";
import { useSelector } from "src/store/index";
import { EmailTypes } from "src/types/emailBotPolicy";

const useEmailBotPolicy = () => {
  const dispatch = useDispatch();

  const { data: emailBotPolicies = [] } = useSelector(
    (state) => state?.EmailBotReducer?.EmailBotPolicies
  );

  const { data: emailtypes = [] } = useSelector(
    (state) => state?.EmailBotReducer?.EmailTypes
  );

  const { data: creditFeaturePolicies = [] } = useSelector(
    (state) => state?.EmailBotReducer?.PoliciesForEmailBot
  );

  const emailBotPolicyInitialState = {
    title: "",
    type: EmailTypes.GMAIL,
    featurePolicyId: "",
  };

  const [loadingStates, setLoadingStates] = useState({
    fetchAllPolicies: false,
    fetchAllEmailPolicies: false,
    createEmailBotPolicy: false,
    updateEmailBotPolicy: false,
    deleteEmailBotPolicy: false,
  });

  const [emailBotPolicyInput, setEmailBotPolicyInput] = useState(
    emailBotPolicyInitialState
  );
  const [updateEmailBotPolicyInput, setUpdateEmailBotPolicyInput] = useState(
    emailBotPolicyInitialState
  );
  const [isCreateEmailBotPolicyModal, setIsCreateEmailBotPolicyModal] =
    useState(false);
  const [isUpdateEmailBotPolicyModal, setIsUpdateEmailBotPolicyModal] =
    useState(false);
  const [selectedEmailBotPolicy, setSelectedEmailBotPolicy] = useState("");
  const [type, setType] = useState(null);

  // const emailTypes = ["GMAIL", "HOTMAIL"];

  const handleLoadingStates = (key: string, value: boolean) => {
    setLoadingStates({
      ...loadingStates,
      [key]: value,
    });
  };
  function getDisplayValue(value: string): string {
    switch (value) {
      case EmailTypes.GMAIL:
        return "GMAIL";
      case EmailTypes.HOTMAIL:
        return "HOTMAIL";
      default:
        return "Unknown";
    }
  }

  const handleEmailBotPolicyInput = (key: string, value: any) => {
    setEmailBotPolicyInput({
      ...emailBotPolicyInput,
      [key]: value,
    });
  };

  const handleUpdateEmailBotPolicyInput = (key: string, value: any) => {
    setUpdateEmailBotPolicyInput({
      ...updateEmailBotPolicyInput,
      [key]: value,
    });
  };

  const handleCloseCreateModal = () => {
    setEmailBotPolicyInput(emailBotPolicyInitialState);
    setIsCreateEmailBotPolicyModal(false);
  };

  const handleUpdateModal = (emailBotPolicy) => {
    if (emailBotPolicy?.status === "A") {
      toast.error("Please inactivate your policy first.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      setSelectedEmailBotPolicy(emailBotPolicy.id);
      console.log("emailBotPolicy", emailBotPolicy);
      setUpdateEmailBotPolicyInput({
        title: emailBotPolicy.title,
        type: emailBotPolicy.type,
        featurePolicyId: emailBotPolicy.featurePolicyId,
      });
      setIsUpdateEmailBotPolicyModal(true);
    }
  };

  const handleCreateEmailBotPolicy = async () => {
    await dispatch(
      createEmailBotPolicy(
        emailBotPolicyInput,
        handleLoadingStates,
        setIsCreateEmailBotPolicyModal
      )
    );
    setEmailBotPolicyInput(emailBotPolicyInitialState);
    dispatch(getAllEmailBotPolicies(handleLoadingStates));
  };

  const handleDeleteEmailBotPolicy = async (id: string) => {
    await dispatch(deleteEmailBotPolicy(id, handleLoadingStates));
    dispatch(getAllEmailBotPolicies(handleLoadingStates));
  };

  const handleUpdateEmailBotPolicy = async () => {
    await dispatch(
      updateEmailBotPolicy(
        updateEmailBotPolicyInput,
        selectedEmailBotPolicy,
        handleLoadingStates,
        setIsUpdateEmailBotPolicyModal
      )
    );
    dispatch(getAllEmailBotPolicies(handleLoadingStates));
  };

  const handleActivateEmailPolicy = async (
    emailBotPolicy: any,
    setSwitchChecked: any
  ) => {
    const activePolicies = emailBotPolicies.filter(
      (policy) =>
        policy.status === "A" &&
        policy.type === emailBotPolicy.type &&
        policy.id !== emailBotPolicy.id
    );
    if (!activePolicies.length) {
      toast.error(
        `You must have at least one active policy of ${emailBotPolicy?.type}. Please activate another policy before deactivating this one.`,
        {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }
      );
      setSwitchChecked(true);
      return;
    }

    await dispatch(
      activateEmailBotPolicy(emailBotPolicy.id, handleLoadingStates)
    );
    dispatch(getAllEmailBotPolicies(handleLoadingStates));
    setSwitchChecked(emailBotPolicy.status !== "A");
  };

  useEffect(() => {
    if (emailtypes?.data?.length === 0)
      dispatch(getAllEmailTypes(handleLoadingStates));
    if (type) {
      dispatch(getEmailBotPoliciesByType(handleLoadingStates, type));
    } else {
      dispatch(getAllEmailBotPolicies(handleLoadingStates));
    }
    dispatch(getCreditFeaturePoliciesForEmailBotPolicies(handleLoadingStates));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type]);

  return {
    emailBotPolicies,
    handleCreateEmailBotPolicy,
    handleCloseCreateModal,
    handleEmailBotPolicyInput,
    emailBotPolicyInput,
    isCreateEmailBotPolicyModal,
    setIsCreateEmailBotPolicyModal,
    creditFeaturePolicies,
    loadingStates,
    getDisplayValue,
    handleDeleteEmailBotPolicy,
    emailtypes,
    handleUpdateModal,
    setIsUpdateEmailBotPolicyModal,
    isUpdateEmailBotPolicyModal,
    updateEmailBotPolicyInput,
    handleUpdateEmailBotPolicy,
    handleUpdateEmailBotPolicyInput,
    handleActivateEmailPolicy,
    type,
    setType,
    // emailtypes,
  };
};

export default useEmailBotPolicy;
