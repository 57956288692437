export const FETCH_EMAIL_BOT_POLICIES_START = "FETCH_EMAIL_BOT_POLICIES_START";
export const FETCH_EMAIL_BOT_POLICIES_RECIEVES = "FETCH_EMAIL_BOT_POLICIES_RECIEVES";
export const FETCH_EMAIL_BOT_POLICIES_END = "FETCH_EMAIL_BOT_POLICIES_END";

export const FETCH_EMAIL_TYPES_START = "FETCH_EMAIL_TYPES_START";
export const FETCH_EMAIL_TYPES_RECIEVES = "FETCH_EMAIL_TYPES_RECIEVES";
export const FETCH_EMAIL_TYPES_END = "FETCH_EMAIL_TYPES_END";

export const FETCH_POLICIES_FOR_EMAIL_POLICIES_START = "FETCH_POLICIES_FOR_EMAIL_POLICIES_START";
export const FETCH_POLICIES_FOR_EMAIL_POLICIES_RECIEVES = "FETCH_POLICIES_FOR_EMAIL_POLICIES_RECIEVES";
export const FETCH_POLICIES_FOR_EMAIL_POLICIES_END = "FETCH_POLICIES_FOR_EMAIL_POLICIES_END";

export const FETCH_SINGLE_EMAIL_BOT_POLICY_START = "FETCH_SINGLE_EMAIL_BOT_POLICY_START";
export const FETCH_SINGLE_EMAIL_BOT_POLICY_RECIEVES = "FETCH_SINGLE_EMAIL_BOT_POLICY_RECIEVES";
export const FETCH_SINGLE_EMAIL_BOT_POLICY_END = "FETCH_SINGLE_EMAIL_BOT_POLICY_END";

export const CREATE_EMAIL_BOT_POLICY_START = "CREATE_EMAIL_BOT_POLICY_START";
export const CREATE_EMAIL_BOT_POLICY_RECIEVES = "CREATE_EMAIL_BOT_POLICY_RECIEVES";
export const CREATE_EMAIL_BOT_POLICY_END = "CREATE_EMAIL_BOT_POLICY_END";

export const UPDATE_EMAIL_BOT_POLICY_START = "UPDATE_EMAIL_BOT_POLICY_START";
export const UPDATE_EMAIL_BOT_POLICY_RECIEVES = "UPDATE_EMAIL_BOT_POLICY_RECIEVES";
export const UPDATE_EMAIL_BOT_POLICY_END = "UPDATE_EMAIL_BOT_POLICY_END";

export const DELETE_EMAIL_BOT_POLICY_START = "DELETE_EMAIL_BOT_POLICY_START";
export const DELETE_EMAIL_BOT_POLICY_RECIEVES = "DELETE_EMAIL_BOT_POLICY_RECIEVES";
export const DELETE_EMAIL_BOT_POLICY_END = "DELETE_EMAIL_BOT_POLICY_END";

export const ACTIVATE_EMAIL_BOT_POLICY_START = "ACTIVATE_EMAIL_BOT_POLICY_START";
export const ACTIVATE_EMAIL_BOT_POLICY_RECIEVES = "ACTIVATE_EMAIL_BOT_POLICY_RECIEVES";
export const ACTIVATE_EMAIL_BOT_POLICY_END = "ACTIVATE_EMAIL_BOT_POLICY_END";