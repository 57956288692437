import { Helmet } from "react-helmet-async";
import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { CreditFeaturePolicyTypes } from "src/types/creditFeaturePolicy";

const ManageFeaturesTab = (props) => {
  const {
    policyFeatureInput,
    handlePolicyFeatureInput,
    getDisplayValue,
    handleCreatePolicyFeature,
    policyFeatures,
    handleDeletePolicyFeature,
    handleFeatureEdit,
    handleUpdatePolicyFeature,
    isUpdateFeature,
  } = props;

  const featureTypes = [
    "Indexes",
    "Resources",
    "Queries",
    "Bot Creation",
    "Bot Queries",
    "Email Bot Creation",
    "Email Bot Queries",
  ];

  return (
    <>
      <Helmet>
        <title>APEXE3 Revolutions</title>
      </Helmet>
      <Container>
        <Box>
          <Grid container mt={3} spacing={5}>
            <Grid xs={6} lg={7} item>
              <Box sx={{ display: "flex", flexDirection: "column" }} gap={5}>
                <Box sx={{ width: "80%" }}>
                  <Box sx={{ minWidth: 120 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={getDisplayValue(policyFeatureInput.featureType)}
                      options={featureTypes}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        handlePolicyFeatureInput(
                          "featureType",
                          CreditFeaturePolicyTypes[value]
                        );
                      }}
                      filterOptions={(options, { inputValue }) => options}
                      renderInput={(params) => (
                        <TextField {...params} label="Feature Type" />
                      )}
                      disabled={isUpdateFeature}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "80%" }}>
                  <TextField
                    value={policyFeatureInput.creditUsage}
                    onChange={(e) =>
                      handlePolicyFeatureInput(
                        "creditUsage",
                        Number(e.target.value)
                      )
                    }
                    id="outlined-number"
                    label="Credit Usage"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box
                sx={{ mt: 7, display: "flex", justifyContent: "space-between" }}
              >
                <Box></Box>
                <Box>
                  <Button
                    onClick={() =>
                      isUpdateFeature
                        ? handleUpdatePolicyFeature()
                        : handleCreatePolicyFeature()
                    }
                    variant="contained"
                    size="large"
                    sx={{ borderRadius: "10px" }}
                  >
                    {isUpdateFeature ? "Update" : "Save"}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} lg={5} item>
              <Box sx={{ display: "flex" }}>
                <Box
                  width={"1.5px"}
                  height={"620px"}
                  bgcolor={"white"}
                  mr={2}
                ></Box>
                <Box width={"100%"}>
                  <Typography sx={{ fontSize: "18px", mt: "10px", mb: "10px" }}>
                    Features List
                  </Typography>
                  {policyFeatures?.length
                    ? policyFeatures?.map((feature) => {
                        return (
                          <Box
                            key={feature?.id}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid gray",
                              px: "15px",
                              py: 1,
                              borderRadius: "10px",
                              mb: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  mt: "10px",
                                  mb: "10px",
                                }}
                              >
                                {getDisplayValue(feature?.featureType)}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  mt: "10px",
                                  mb: "10px",
                                }}
                              >
                                {feature?.creditUsage}
                              </Typography>
                            </Box>

                            <Box>
                              <Button
                                onClick={() =>
                                  handleFeatureEdit(
                                    feature?.id,
                                    feature?.featureType,
                                    feature?.creditUsage
                                  )
                                }
                                size="small"
                                variant="text"
                                sx={{ fontSize: "14px" }}
                              >
                                Edit
                              </Button>
                              /
                              <Button
                                onClick={() =>
                                  handleDeletePolicyFeature(feature?.id)
                                }
                                size="small"
                                variant="text"
                                sx={{ fontSize: "14px" }}
                              >
                                Delete
                              </Button>
                            </Box>
                          </Box>
                        );
                      })
                    : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ManageFeaturesTab;
