export enum CreditFeaturePolicyTypes {
  Indexes = 'INDEX_CREATE',
  Resources = 'RESOURCE_CREATE',
  Queries = 'INDEX_SEARCH',
  "Bot Creation" = "BOT_CREATE",
  "Bot Queries" = "BOT_QUERY",
  "Email Bot Creation" = "EMAIL_BOT_CREATE",
  "Email Bot Queries" = "EMAIL_BOT_QUERY",
}

export interface CreateCreditFeaturePolicy {
  title:  string;
  description: string;
}

export interface CreatePolicyFeature {
  featurePolicyId: string;
  featureType:  CreditFeaturePolicyTypes;
  creditUsage: number;
}