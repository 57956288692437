import { emailBotPolicy } from "src/__Api__/emailBotPolicyApi";
import {
  FETCH_EMAIL_BOT_POLICIES_START,
  FETCH_EMAIL_BOT_POLICIES_RECIEVES,
  FETCH_EMAIL_BOT_POLICIES_END,
  FETCH_SINGLE_EMAIL_BOT_POLICY_START,
  FETCH_SINGLE_EMAIL_BOT_POLICY_RECIEVES,
  FETCH_SINGLE_EMAIL_BOT_POLICY_END,
  CREATE_EMAIL_BOT_POLICY_START,
  CREATE_EMAIL_BOT_POLICY_RECIEVES,
  CREATE_EMAIL_BOT_POLICY_END,
  UPDATE_EMAIL_BOT_POLICY_START,
  UPDATE_EMAIL_BOT_POLICY_RECIEVES,
  UPDATE_EMAIL_BOT_POLICY_END,
  DELETE_EMAIL_BOT_POLICY_START,
  DELETE_EMAIL_BOT_POLICY_RECIEVES,
  DELETE_EMAIL_BOT_POLICY_END,
  ACTIVATE_EMAIL_BOT_POLICY_START,
  ACTIVATE_EMAIL_BOT_POLICY_RECIEVES,
  ACTIVATE_EMAIL_BOT_POLICY_END,
  FETCH_EMAIL_TYPES_START,
  FETCH_EMAIL_TYPES_RECIEVES,
  FETCH_EMAIL_TYPES_END,
  FETCH_POLICIES_FOR_EMAIL_POLICIES_START,
  FETCH_POLICIES_FOR_EMAIL_POLICIES_RECIEVES,
  FETCH_POLICIES_FOR_EMAIL_POLICIES_END,
} from "./action-types";

import { isString } from "lodash";
import {
  CreateEmailBotPolicy,
  EmailTypes,
  UpdateEmailBotPolicy,
} from "src/types/emailBotPolicy";

export const getAllEmailBotPolicies =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("fetchAllEmailPolicies", true);
      dispatch({ type: FETCH_EMAIL_BOT_POLICIES_START });

      const result = await emailBotPolicy.getAllEmailBotPolicies();
      handleLoadingStates("fetchAllEmailPolicies", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_EMAIL_BOT_POLICIES_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_EMAIL_BOT_POLICIES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_EMAIL_BOT_POLICIES_END,
        data: [],
        error,
      });
    }
  };

  export const getCreditFeaturePoliciesForEmailBotPolicies =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("fetchAllEmailPolicies", true);
      dispatch({ type: FETCH_POLICIES_FOR_EMAIL_POLICIES_START });

      const result = await emailBotPolicy.getCreditFeaturePoliciesForEmailBotPolicies();
      handleLoadingStates("fetchAllEmailPolicies", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_POLICIES_FOR_EMAIL_POLICIES_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_POLICIES_FOR_EMAIL_POLICIES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_POLICIES_FOR_EMAIL_POLICIES_END,
        data: [],
        error,
      });
    }
  };

export const getAllEmailTypes =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("fetchAllEmailTypes", true);
      dispatch({ type: FETCH_EMAIL_TYPES_START });

      const result = await emailBotPolicy.getAllEmailTypes();
      handleLoadingStates("fetchAllEmailTypes", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_EMAIL_TYPES_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_EMAIL_TYPES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_EMAIL_TYPES_END,
        data: [],
        error,
      });
    }
  };

export const getEmailBotPoliciesByType =
  (handleLoadingStates: any, type: EmailTypes) => async (dispatch) => {
    
    try {
      handleLoadingStates("fetchAllEmailPolicies", true);
      dispatch({ type: FETCH_EMAIL_BOT_POLICIES_START });

      const result = await emailBotPolicy.getEmailBotPoliciesByType(type);
      handleLoadingStates("fetchAllEmailPolicies", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_EMAIL_BOT_POLICIES_END,
          data: [],
          error: result,
        });
      } else {
        console.log("getEmailBotPoliciesByType", result)
        dispatch({
          type: FETCH_EMAIL_BOT_POLICIES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_EMAIL_BOT_POLICIES_END,
        data: [],
        error,
      });
    }
  };

export const getEmailBotPolicy = (id: string) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SINGLE_EMAIL_BOT_POLICY_START });

    const result = await emailBotPolicy.getEmailBotPolicy(id);
    if (isString(result)) {
      dispatch({
        type: FETCH_SINGLE_EMAIL_BOT_POLICY_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_SINGLE_EMAIL_BOT_POLICY_RECIEVES,
        data: result,
        error: "",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_EMAIL_BOT_POLICY_END,
      data: [],
      error,
    });
  }
};

export const createEmailBotPolicy =
  (
    body: CreateEmailBotPolicy,
    handleLoadingStates: any,
    setIsCreateEmailBotPolicyModal: any
  ) =>
  async (dispatch) => {
    try {
      handleLoadingStates("createEmailBotPolicy", true);
      dispatch({ type: CREATE_EMAIL_BOT_POLICY_START });

      const result = await emailBotPolicy.createEmailBotPolicy(body);
      handleLoadingStates("createEmailBotPolicy", false);
      if (result && result?.id) {
        setIsCreateEmailBotPolicyModal(false);
        dispatch({
          type: CREATE_EMAIL_BOT_POLICY_RECIEVES,
          data: result,
          error: "",
        });
        return result?.id;
      } else {
        dispatch({
          type: CREATE_EMAIL_BOT_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_EMAIL_BOT_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const updateEmailBotPolicy =
  (
    body: UpdateEmailBotPolicy,
    id: string,
    handleLoadingStates: any,
    setIsUpdateEmailBotPolicyModal: any
  ) =>
  async (dispatch) => {
    try {
      handleLoadingStates("updateEmailBotPolicy", true);
      dispatch({ type: UPDATE_EMAIL_BOT_POLICY_START });

      const result = await emailBotPolicy.updateEmailBotPolicy(body, id);
      handleLoadingStates("updateEmailBotPolicy", false);
      setIsUpdateEmailBotPolicyModal(false);
      if (result && result?.id) {
        dispatch({
          type: UPDATE_EMAIL_BOT_POLICY_RECIEVES,
          data: result,
          error: "",
        });
      } else {
        dispatch({
          type: UPDATE_EMAIL_BOT_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_EMAIL_BOT_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const deleteEmailBotPolicy =
  (id: string, handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("deleteEmailBotPolicy", true);
      dispatch({ type: DELETE_EMAIL_BOT_POLICY_START });

      const result = await emailBotPolicy.deleteEmailBotPolicy(id);
      handleLoadingStates("deleteEmailBotPolicy", false);
      if (result === "done") {
        dispatch({
          type: DELETE_EMAIL_BOT_POLICY_RECIEVES,
          data: result,
          error: "",
        });
      } else {
        dispatch({
          type: DELETE_EMAIL_BOT_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_EMAIL_BOT_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const activateEmailBotPolicy =
  (id: string, handleLoadingStates: any) => async (dispatch) => {
    try {
      dispatch({ type: ACTIVATE_EMAIL_BOT_POLICY_START });
      handleLoadingStates("activatePolicy", true);
      const result = await emailBotPolicy.activateEmailBotPolicy(id);
      handleLoadingStates("activatePolicy", false);
      if (isString(result)) {
        dispatch({
          type: ACTIVATE_EMAIL_BOT_POLICY_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: ACTIVATE_EMAIL_BOT_POLICY_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: ACTIVATE_EMAIL_BOT_POLICY_END,
        data: [],
        error,
      });
    }
  };
