export const FETCH_ALL_BOT_THEMES_START = "FETCH_ALL_BOT_THEMES_START";
export const FETCH_ALL_BOT_THEMES_RECIEVES = "FETCH_ALL_BOT_THEMES_RECIEVES";
export const FETCH_ALL_BOT_THEMES_END = "FETCH_ALL_BOT_THEMES_END";

export const FETCH_SINGLE_BOT_THEME_START = "FETCH_SINGLE_BOT_THEME_START";
export const FETCH_SINGLE_BOT_THEME_RECIEVES = "FETCH_SINGLE_BOT_THEME_RECIEVES";
export const FETCH_SINGLE_BOT_THEME_END = "FETCH_SINGLE_BOT_THEME_END";

export const FETCH_POLICIES_FOR_BOT_THEMES_START = "FETCH_POLICIES_FOR_BOT_THEMES_START";
export const FETCH_POLICIES_FOR_BOT_THEMES_RECIEVES = "FETCH_POLICIES_FOR_BOT_THEMES_RECIEVES";
export const FETCH_POLICIES_FOR_BOT_THEMES_END = "FETCH_POLICIES_FOR_BOT_THEMES_END";
