import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import SubscriptionManagementPage from './pages/dashboard/SubscriptionManagementPage';
import FreeStarterPlanPage from './pages/dashboard/FreeStarterPlanPage';
import MeteredSubscriptionPage from './pages/dashboard/MeteredSubscriptionPage';
import CreditsFeaturePolicyPage from './pages/dashboard/CreditsFeaturePolicyPage';
import CreateCreditFeaturePolicy from './pages/dashboard/CreateCreditFeaturePolicy';
import BotThemePage from './pages/dashboard/BotThemePage';
import EmailBotPolicyPage from './pages/dashboard/EmailBotPolicyPage';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Home = Loadable(lazy(() => import('./pages/Home')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SubscriptionManagementPage />
      },
      {
        path: 'credit-feature-policy',
        element: <CreditsFeaturePolicyPage />
      },
      {
        path: 'credit-feature-policy-form',
        element: <CreateCreditFeaturePolicy />
      },
      {
        path: 'free-starter-plan',
        element: <FreeStarterPlanPage />
      },
      {
        path: 'metered-subscription-plan',
        element: <MeteredSubscriptionPage />
      },
      {
        path: 'bot-themes',
        element: <BotThemePage />
      },
      {
        path: 'email-bot-policies',
        element: <EmailBotPolicyPage />
      },

      
    ]
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  }
];


export default routes;
