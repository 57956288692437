import { Box, Switch, TableCell } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Trash from "src/icons/Trash";
import PencilAlt from "src/icons/PencilAlt";

import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";

const ActionColumn = (props) => {
  const { emailBotPolicy, handleDelete, handleEdit, handleActivate } = props;

  const [switchChecked, setSwitchChecked] = useState(
    emailBotPolicy?.status === "A"
  );

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#76d14d",
      "&:hover": {
        backgroundColor: alpha("#76d14d", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#76d14d",
    },
  }));

  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => handleDelete(emailBotPolicy?.id)}
        >
          <IconButton>
            <Trash color="error" />
          </IconButton>
        </Box>
        <IconButton onClick={() => handleEdit(emailBotPolicy)}>
          <PencilAlt />
        </IconButton>

        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => handleActivate(emailBotPolicy, setSwitchChecked)}
        >
          <GreenSwitch checked={switchChecked} />
        </Box>
      </Box>
    </TableCell>
  );
};

export default ActionColumn;
