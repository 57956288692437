import { combineReducers } from "@reduxjs/toolkit";
import {
  FETCH_EMAIL_BOT_POLICIES_START,
  FETCH_EMAIL_BOT_POLICIES_RECIEVES,
  FETCH_EMAIL_BOT_POLICIES_END,
  FETCH_SINGLE_EMAIL_BOT_POLICY_START,
  FETCH_SINGLE_EMAIL_BOT_POLICY_RECIEVES,
  FETCH_SINGLE_EMAIL_BOT_POLICY_END,
  CREATE_EMAIL_BOT_POLICY_START,
  CREATE_EMAIL_BOT_POLICY_RECIEVES,
  CREATE_EMAIL_BOT_POLICY_END,
  UPDATE_EMAIL_BOT_POLICY_START,
  UPDATE_EMAIL_BOT_POLICY_RECIEVES,
  UPDATE_EMAIL_BOT_POLICY_END,
  DELETE_EMAIL_BOT_POLICY_START,
  DELETE_EMAIL_BOT_POLICY_RECIEVES,
  DELETE_EMAIL_BOT_POLICY_END,
  FETCH_EMAIL_TYPES_START,
  FETCH_EMAIL_TYPES_RECIEVES,
  FETCH_EMAIL_TYPES_END,
  FETCH_POLICIES_FOR_EMAIL_POLICIES_START,
  FETCH_POLICIES_FOR_EMAIL_POLICIES_RECIEVES,
  FETCH_POLICIES_FOR_EMAIL_POLICIES_END,
} from "./action-types";

export const EmailBotPolicies = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_EMAIL_BOT_POLICIES_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_EMAIL_BOT_POLICIES_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_EMAIL_BOT_POLICIES_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const PoliciesForEmailBot = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_POLICIES_FOR_EMAIL_POLICIES_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_POLICIES_FOR_EMAIL_POLICIES_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_POLICIES_FOR_EMAIL_POLICIES_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const EmailTypes = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_EMAIL_TYPES_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_EMAIL_TYPES_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_EMAIL_TYPES_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const EmailBotPolicy = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_SINGLE_EMAIL_BOT_POLICY_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_SINGLE_EMAIL_BOT_POLICY_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_SINGLE_EMAIL_BOT_POLICY_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const CreateEmailBotPolicy = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case CREATE_EMAIL_BOT_POLICY_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case CREATE_EMAIL_BOT_POLICY_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case CREATE_EMAIL_BOT_POLICY_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export const UpdateEmailBotPolicy = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case UPDATE_EMAIL_BOT_POLICY_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case UPDATE_EMAIL_BOT_POLICY_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case UPDATE_EMAIL_BOT_POLICY_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};
export const DeleteEmailBotPolicy = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case DELETE_EMAIL_BOT_POLICY_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case DELETE_EMAIL_BOT_POLICY_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case DELETE_EMAIL_BOT_POLICY_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  EmailBotPolicies,
  EmailBotPolicy,
  CreateEmailBotPolicy,
  UpdateEmailBotPolicy,
  DeleteEmailBotPolicy,
  EmailTypes,
  PoliciesForEmailBot
});
