import {
  FETCH_CREDIT_FEATURE_POLICIES_START,
  FETCH_CREDIT_FEATURE_POLICIES_RECIEVES,
  FETCH_CREDIT_FEATURE_POLICIES_END,
  CREATE_CREDIT_FEATURE_POLICY_START,
  CREATE_CREDIT_FEATURE_POLICY_RECIEVES,
  CREATE_CREDIT_FEATURE_POLICY_END,
  UPDATE_CREDIT_FEATURE_POLICY_START,
  UPDATE_CREDIT_FEATURE_POLICY_RECIEVES,
  UPDATE_CREDIT_FEATURE_POLICY_END,
  DELETE_CREDIT_FEATURE_POLICY_START,
  DELETE_CREDIT_FEATURE_POLICY_RECIEVES,
  DELETE_CREDIT_FEATURE_POLICY_END,
  FETCH_SINGLE_CREDIT_FEATURE_POLICY_END,
  FETCH_SINGLE_CREDIT_FEATURE_POLICY_RECIEVES,
  FETCH_SINGLE_CREDIT_FEATURE_POLICY_START,
  ACTIVATE_CREDIT_FEATURE_POLICY_END,
  ACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES,
  ACTIVATE_CREDIT_FEATURE_POLICY_START,
  DEACTIVATE_CREDIT_FEATURE_POLICY_END,
  DEACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES,
  DEACTIVATE_CREDIT_FEATURE_POLICY_START,
  SET_CREDIT_FEATURE_POLICY_DEFAULT_END,
  SET_CREDIT_FEATURE_POLICY_DEFAULT_RECIEVES,
  SET_CREDIT_FEATURE_POLICY_DEFAULT_START,
} from "./action-types";

import { creditFeaturePolicyApi } from "src/__Api__/creditFeaturePoliciesApi";
import { isString } from "lodash";

export const getAllCreditFeaturePolicies =
  (handleLoadingStates: any) => async (dispatch) => {
    try {
      handleLoadingStates("fetchAllPolicies", true);
      dispatch({ type: FETCH_CREDIT_FEATURE_POLICIES_START });

      const result = await creditFeaturePolicyApi.getAllCreditFeaturePolicies();
      handleLoadingStates("fetchAllPolicies", false);
      if (isString(result)) {
        dispatch({
          type: FETCH_CREDIT_FEATURE_POLICIES_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: FETCH_CREDIT_FEATURE_POLICIES_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_CREDIT_FEATURE_POLICIES_END,
        data: [],
        error,
      });
    }
  };

export const getCreditFeaturePolicy = (id: string) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SINGLE_CREDIT_FEATURE_POLICY_START });

    const result = await creditFeaturePolicyApi.getCreditFeaturePolicy(id);
    if (isString(result)) {
      dispatch({
        type: FETCH_SINGLE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: FETCH_SINGLE_CREDIT_FEATURE_POLICY_RECIEVES,
        data: result,
        error: "",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_CREDIT_FEATURE_POLICY_END,
      data: [],
      error,
    });
  }
};

export const createCreditFeaturePolicy =
  (body: any, setIsLoading?: any) => async (dispatch) => {
    try {
      // setIsLoading(true)
      dispatch({ type: CREATE_CREDIT_FEATURE_POLICY_START });

      const result = await creditFeaturePolicyApi.createCreditFeaturePolicy(
        body
      );
      // setIsLoading(false);
      if (result && result?.id) {
        dispatch({
          type: CREATE_CREDIT_FEATURE_POLICY_RECIEVES,
          data: result,
          error: "",
        });
        return result?.id;
      } else {
        dispatch({
          type: CREATE_CREDIT_FEATURE_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const updateFeature =
  (body: any, id: string, setIsLoading: any) => async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch({ type: UPDATE_CREDIT_FEATURE_POLICY_START });

      const result = await creditFeaturePolicyApi.updateCreditFeaturePolicy(
        body,
        id
      );
      setIsLoading(false);
      if (result && result?.id) {
        dispatch({
          type: UPDATE_CREDIT_FEATURE_POLICY_RECIEVES,
          data: result,
          error: "",
        });
      } else {
        dispatch({
          type: UPDATE_CREDIT_FEATURE_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const updateCreditFeaturePolicy =
  (body: any, id: string, setIsLoading?: any) => async (dispatch) => {
    try {
      // setIsLoading(true)
      dispatch({ type: UPDATE_CREDIT_FEATURE_POLICY_START });

      const result = await creditFeaturePolicyApi.updateCreditFeaturePolicy(
        body,
        id
      );
      // setIsLoading(false)
      if (result && result?.id) {
        dispatch({
          type: UPDATE_CREDIT_FEATURE_POLICY_RECIEVES,
          data: result,
          error: "",
        });
      } else {
        dispatch({
          type: UPDATE_CREDIT_FEATURE_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const deleteFeature =
  (id: string, setIsLoading?: any) => async (dispatch) => {
    try {
      // setIsLoading(true)
      dispatch({ type: DELETE_CREDIT_FEATURE_POLICY_START });

      const result = await creditFeaturePolicyApi.deleteCreditFeaturePolicy(id);
      // setIsLoading(false)
      if (result === "done") {
        dispatch({
          type: DELETE_CREDIT_FEATURE_POLICY_RECIEVES,
          data: result,
          error: "",
        });
        // dispatch(featureList(subscriptionId));
      } else {
        dispatch({
          type: DELETE_CREDIT_FEATURE_POLICY_END,
          data: [],
          error: result,
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const activateCreditFeaturePolicy = (id: string) => async (dispatch) => {
  try {
    dispatch({ type: ACTIVATE_CREDIT_FEATURE_POLICY_START });

    const result = await creditFeaturePolicyApi.activateCreditFeaturePolicy(id);
    if (isString(result)) {
      dispatch({
        type: ACTIVATE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error: result,
      });
    } else {
      dispatch({
        type: ACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES,
        data: result,
        error: "",
      });
    }
  } catch (error) {
    dispatch({
      type: ACTIVATE_CREDIT_FEATURE_POLICY_END,
      data: [],
      error,
    });
  }
};

export const deactivateCreditFeaturePolicy =
  (id: string) => async (dispatch) => {
    try {
      dispatch({ type: DEACTIVATE_CREDIT_FEATURE_POLICY_START });

      const result = await creditFeaturePolicyApi.deactivateCreditFeaturePolicy(
        id
      );
      if (isString(result)) {
        dispatch({
          type: DEACTIVATE_CREDIT_FEATURE_POLICY_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: DEACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: DEACTIVATE_CREDIT_FEATURE_POLICY_END,
        data: [],
        error,
      });
    }
  };

export const setCreditFeaturePolicyAsDefault =
  (id: string) => async (dispatch) => {
    try {
      dispatch({ type: SET_CREDIT_FEATURE_POLICY_DEFAULT_START });

      const result =
        await creditFeaturePolicyApi.setCreditFeaturePolicyAsDeafult(id);
      if (isString(result)) {
        dispatch({
          type: SET_CREDIT_FEATURE_POLICY_DEFAULT_END,
          data: [],
          error: result,
        });
      } else {
        dispatch({
          type: SET_CREDIT_FEATURE_POLICY_DEFAULT_RECIEVES,
          data: result,
          error: "",
        });
      }
    } catch (error) {
      dispatch({
        type: SET_CREDIT_FEATURE_POLICY_DEFAULT_END,
        data: [],
        error,
      });
    }
  };
